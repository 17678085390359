#page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
}
#page .column {
  flex-direction: column;
}
#login {
  flex: 0;
  border-radius:5px;
  padding:25px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(1, 2, 2, 0.05);
}
#login img {
  margin-bottom:20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
#login h2 {
  margin-bottom:7px !important;
}
#login a.orangeLink:link {
  color: #ef9000;
  font-weight:bold;
}
#login a.orangeLink:hover {
  color: #333333;
}
#login a.orangeLink:visited {
  color: #ef9000;
}
#callToAction {
  margin-top:40px;
}
#callToAction a.createAccount:link {
  font-size:18px;
  color: #ef9000;
  font-weight:normal;
}
#callToAction a.createAccount:hover {
  color: #333333;
}
#callToAction a.createAccount:visited {
  color: #ef9000;
}
.form-control {
  height:50px !important;
  border-color:#ef9000 !important;
  border-radius:5px !important;
  color:#333 !important;
}
.btn.btn-info-sub {
  background: #ef9000;
  border-color: #ef9000;
  color: #fff;
  font-weight: 700;
}
.btn.btn-md {
  width: 100px;
  min-width: 100px;
  letter-spacing: 1px;
  border-radius:5px;
}
#footerLinks {
  flex: 1;
  margin-top:20px;
}
#footerLinks a.leftLinks:link {
  color: #333333;
}
#footerLinks a.leftLinks:hover {
  color: #ef9000;
}
#footerLinks a.leftLinks:visited {
  color: #333333;
}
#footerLinks a.rightLinks:link {
  color: #333333;
}
#footerLinks a.rightLinks:hover {
  color: #ef9000;
}
#footerLinks a.rightLinks:visited {
  color: #333333;
}
@media screen and (max-width: 480px) {
  #login {
    max-width: 300px;
  }
  .btn.btn-md {
    font-size: 16px;
    line-height: 20px;
    padding: 12px;
  }
  #footerLinks {
    width: 300px;
  }
  #footerLinks a.rightLinks:link {
    margin-left:20px;
  }
}
@media screen and (min-width: 480px) {
  #login {
    max-width: 600px;
  }
  .btn.btn-md {
    font-size: 18px;
    line-height: 24px;
    padding: 12px;
  }
  #footerLinks {
    width: 600px;
  }
  #footerLinks a.leftLinks:link {
    margin-left:26px;
  }
  #footerLinks a.rightLinks:link {
    margin-right:27px;
  }
}
